'use client'
import { logger } from '@/utils/logger'
import {
	Box,
	Button,
	Center,
	Group,
	InputError,
	Stack,
	Tabs,
	Text,
	TextInput,
	Title,
	useMantineTheme,
	Anchor,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { type ReactNode, useState } from 'react'
import CrewHeroLogoV3 from '@/components/CrewHeroLogoV3'
import CrewHeroCard from '@/components/CrewHeroCard'
import { useRouter } from 'next/navigation'
import { AuthError } from '@supabase/supabase-js'
import { checkForProfile, otpSignin, passwordLogin } from '@/authActions/authServerActions'
import { IconSparkles } from '@tabler/icons-react'
import { createEmailOTP, createSMSOTP } from '@/authActions/loginActions'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import { notifications } from '@mantine/notifications'
import { toastAndReportError } from '@/utils/errorUtils.client'

const AuthLoginPage = () => {
	const theme = useMantineTheme()
	const router = useRouter()
	const [error, setError] = useState<string | ReactNode>()
	const [submitting, setSubmitting] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<'email' | 'phone' | 'password'>('email')

	const signinForm = useForm<{ email: string; phone: string; password: string }>({
		initialValues: { email: '', phone: '', password: '' },
	})

	const handleOTPSignin = async (values: typeof signinForm.values) => {
		try {
			setSubmitting(true)
			setError(undefined)

			if (activeTab === 'email' && values.email) {
				const email = values.email.trim().toLowerCase()
				const isLogin = await checkForProfile(email)
				logger.info('isLogin', isLogin, email)
				const result = await otpSignin(email, isLogin)

				if (!result) throw new Error('No response from otpSignin function.')

				if (isLogin) {
					await createEmailOTP(email)
				}

				const { status, message } = result
				if (status === 'error') throw new Error(message)

				router.push(
					isLogin ? `/auth/otp?email=${encodeURIComponent(email)}` : '/auth/confirm-account',
				)
			} else if (activeTab === 'phone' && values.phone) {
				const phone = values.phone.trim()
				const result = await createSMSOTP(phone)
				if (result.ok) {
					router.push(`/auth/verify-phone-otp?phone=${encodeURIComponent(phone)}`)
				} else if (result.code === 'otp_signup_not_allowed') {
					setError(
						<Text size='xs' span>
							User for this cell not setup. Please contact{' '}
							<Anchor href='mailto:support@crewhero.app'>support@crewhero.app</Anchor>
						</Text>,
					)
				} else if (result.message) {
					setError(result.message)
				} else {
					throw new Error('An unknown error occurred')
				}
			}
		} catch (error) {
			if (error instanceof AuthError) {
				setError(error.message)
			} else if (error instanceof Error) {
				setError(error.message)
			} else {
				logger.error('handleOTPSignin error', error)
				setError('An unknown error occurred')
			}
		} finally {
			setSubmitting(false)
		}
	}

	const handlePasswordLogin = async (values: typeof signinForm.values) => {
		try {
			setSubmitting(true)
			setError(undefined)
			const email = values.email.trim().toLowerCase()
			const password = values.password

			const { ok } = await passwordLogin(email, password)
			if (!ok) throw new Error('Invalid email or password')

			notifications.show({
				title: 'Login successful',
				message: 'You have successfully signed in!',
				color: 'green',
			})

			router.push('/manager')
		} catch (error) {
			toastAndReportError(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Stack
			style={{
				background:
					'linear-gradient(180deg, var(--mantine-color-teal-6) 0%, var(--mantine-color-teal-5) 50%, var(--mantine-color-green-4) 100%)',
			}}
			h='100vh'
		>
			<Box m='auto'>
				<CrewHeroCard px='lg' py='lg' maw='320px'>
					<Stack>
						<CrewHeroLogoV3 />
						<Box>
							<Center py='md'>
								<Title order={3}>Let's get you signed in</Title>
							</Center>
						</Box>

						<Tabs
							value={activeTab}
							onChange={(value) => setActiveTab(value as 'email' | 'phone' | 'password')}
						>
							<Tabs.List justify='center' grow>
								<Tabs.Tab value='email'>Email</Tabs.Tab>
								<Tabs.Tab value='phone'>Phone</Tabs.Tab>
								<Tabs.Tab value='password'>Password</Tabs.Tab>
							</Tabs.List>

							<Tabs.Panel value='email' pt='md'>
								<form onSubmit={signinForm.onSubmit(handleOTPSignin)}>
									<TextInput
										{...signinForm.getInputProps('email')}
										label='Email address'
										placeholder='hello@gmail.com'
										size='md'
										type='email'
										autoFocus
									/>
									<InputError mt='sm'>{error}</InputError>
									<Button
										fullWidth
										mt='md'
										size='md'
										type='submit'
										disabled={submitting}
										loading={submitting}
										loaderProps={{ type: 'bars', size: 'xs' }}
									>
										Continue
									</Button>
								</form>
							</Tabs.Panel>

							<Tabs.Panel value='phone' pt='md'>
								<form onSubmit={signinForm.onSubmit(handleOTPSignin)}>
									<PhoneInput
										{...signinForm.getInputProps('phone')}
										defaultCountry='us'
										inputStyle={{ width: '100%', height: '36px' }}
									/>
									<InputError mt='sm'>{error}</InputError>
									<Button
										fullWidth
										mt='md'
										size='md'
										type='submit'
										disabled={submitting}
										loading={submitting}
										loaderProps={{ type: 'bars', size: 'xs' }}
									>
										Continue
									</Button>
								</form>
							</Tabs.Panel>

							<Tabs.Panel value='password' pt='md'>
								<form onSubmit={signinForm.onSubmit(handlePasswordLogin)}>
									<TextInput
										{...signinForm.getInputProps('email')}
										label='Email address'
										placeholder='hello@gmail.com'
										size='md'
										type='email'
										autoFocus
									/>
									<TextInput
										{...signinForm.getInputProps('password')}
										label='Password'
										placeholder='Your password'
										size='md'
										type='password'
										mt='md'
									/>
									<Anchor mt='xs' size='sm' onClick={() => router.push('/login/forgot-password')}>
										Forgot password?
									</Anchor>
									<InputError mt='sm'>{error}</InputError>
									<Button
										fullWidth
										mt='md'
										size='md'
										type='submit'
										disabled={submitting}
										loading={submitting}
										loaderProps={{ type: 'bars', size: 'xs' }}
									>
										Sign In
									</Button>
								</form>
							</Tabs.Panel>
						</Tabs>

						<CrewHeroCard p='sm' bg={theme.colors.bodyShaded[0]}>
							<Group wrap='nowrap' align='center'>
								<Box>
									<IconSparkles stroke={1} />
								</Box>
								<Box>
									<Text size='sm'>We'll send a secure link or OTP for password-free sign in.</Text>
								</Box>
							</Group>
						</CrewHeroCard>
					</Stack>
				</CrewHeroCard>
			</Box>
		</Stack>
	)
}

export default AuthLoginPage
