'use client'

import { MantineProvider } from '@mantine/core'
import type { FC, ReactNode } from 'react'
import { useMemoryMonitor } from '@/hooks/useMemoryMonitor'
import { cssVariablesResolver, theme } from '@/theme'

const ClientThemeWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	useMemoryMonitor()
	return (
		<MantineProvider
			defaultColorScheme='light'
			theme={theme}
			cssVariablesResolver={cssVariablesResolver}
		>
			{children}
		</MantineProvider>
	)
}

export default ClientThemeWrapper
