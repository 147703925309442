'use client'
import { Button, Card, type CardProps, Group, Title, useMantineTheme } from '@mantine/core'
import type { Icon123 } from '@tabler/icons-react'
import type React from 'react'
import type { ReactNode } from 'react'

type CrewHeroCardProps = {
	children: React.ReactNode
	title?: string
	LeftIcon?: typeof Icon123
	action?: { label: string | ReactNode; onClick: () => void; icon?: ReactNode } | undefined
	tapAnimate?: boolean
	onClick?: () => void
} & CardProps

const CrewHeroCard: React.FC<CrewHeroCardProps> = ({
	children,
	title,
	LeftIcon,
	action,
	tapAnimate,
	onClick,
	style,
	...rest
}) => {
	const theme = useMantineTheme()
	return (
		<Card
			onClick={onClick}
			style={{
				cursor: onClick ? 'pointer' : 'default',
				borderBottom: '2px solid',
				borderColor: theme.colors.dividerStrong[0],
				...(style || {}),
			}}
			p={0}
			radius='md'
			shadow='sm'
			bg={theme.colors.body[0]}
			{...rest}
		>
			{title && (
				<Group justify='space-between' p={'md'}>
					<Group align='center' justify='flex-start' gap='sm'>
						{LeftIcon && <LeftIcon stroke={1.0} size={16} />}

						<Title order={6} style={{ verticalAlign: 'middle' }}>
							{title}
						</Title>
					</Group>
					{action && (
						<Button
							px={0}
							variant='transparent'
							size='xs'
							onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
								e.nativeEvent.stopPropagation()
								action.onClick()
							}}
							style={{ verticalAlign: 'top' }}
							justify='center'
							color='gray'
							leftSection={action.icon}
						>
							{action.label}
						</Button>
					)}
				</Group>
			)}
			{children}
		</Card>
	)
}

export default CrewHeroCard
