'use client'

import { Center, Loader } from '@mantine/core'

const LoginLoading = () => {
	return (
		<Center>
			<Loader />
		</Center>
	)
}

export default LoginLoading
