'use client'

import { colorsTuple, createTheme, type CSSVariablesResolver, virtualColor } from '@mantine/core'
import { Nunito, Outfit } from 'next/font/google'

const outfit = Outfit({ subsets: ['latin'] })
const nunito = Nunito({ subsets: ['latin'] })

export const theme = createTheme({
	defaultRadius: 'md',
	autoContrast: true,
	luminanceThreshold: 0.4,
	fontFamily: nunito.style.fontFamily,
	headings: { fontFamily: outfit.style.fontFamily, fontWeight: '500' },
	primaryColor: 'teal',
	colors: {
		bodyLight: colorsTuple('#fff'), // --mantine-color-body
		bodyDark: colorsTuple('#242424'), // --mantine-color-dark-7
		body: virtualColor({
			name: 'body',
			light: 'bodyLight',
			dark: 'bodyDark',
		}),
		primaryTextLight: colorsTuple('#12b886'), // --mantine-color-teal-6
		primaryTextDark: colorsTuple('#38b9a9'),
		primaryText: virtualColor({
			name: 'primaryText',
			light: 'primaryTextLight',
			dark: 'primaryTextDark',
		}),
		dividerDimLight: colorsTuple('#868e9626'),
		dividerDimDark: colorsTuple('#868e961a'),
		dividerDim: virtualColor({
			name: 'dividerDim',
			light: 'dividerDimLight',
			dark: 'dividerDimDark',
		}),
		dividerStrongLight: colorsTuple('#2e2e2e1a'),
		dividerStrongDark: colorsTuple('#000'),
		dividerStrong: virtualColor({
			name: 'dividerStrong',
			light: 'dividerStrongLight',
			dark: 'dividerStrongDark',
		}),
		brightLight: colorsTuple('#141414'), // --mantine-color-dark-9
		brightDark: colorsTuple('#fff'),
		bright: virtualColor({
			name: 'bright',
			light: 'brightLight',
			dark: 'brightDark',
		}),
		bodyShadedLight: colorsTuple('#f3f4f4'),
		bodyShadedDark: colorsTuple('#2e2f2f'),
		bodyShaded: virtualColor({
			name: 'bodyShaded',
			light: 'bodyShadedLight',
			dark: 'bodyShadedDark',
		}),
		glassShadedLight: colorsTuple('#868e961a'),
		glassShadedDark: colorsTuple('#868e961a'),
		glassShaded: virtualColor({
			name: 'glassShaded',
			light: 'glassShadedLight',
			dark: 'glassShadedDark',
		}),
		successLight: colorsTuple('#12b886'), // --mantine-color-teal-6
		successDark: colorsTuple('#96f2d7'), // --mantine-color-teal-2
		success: virtualColor({
			name: 'success',
			light: 'successLight',
			dark: 'successDark',
		}),
	},
})

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
	variables: {
		'--checkbox-radius': 'var(--mantine-radius-sm)',
	},
	light: {
		'--body': theme.colors.bodyLight[0],
		'--primary-text': theme.colors.primaryTextLight[0],
		'--divider-dim': theme.colors.dividerDimLight[0],
		'--divider-strong': theme.colors.dividerStrongLight[0],
		'--bright': theme.colors.brightLight[0],
		'--body-shaded': theme.colors.bodyShadedLight[0],
		'--glass-shaded': theme.colors.glassShadedLight[0],
		'--success': theme.colors.successLight[0],
	},
	dark: {
		'--body': theme.colors.bodyDark[0],
		'--primary-text': theme.colors.primaryTextDark[0],
		'--divider-dim': theme.colors.dividerDimDark[0],
		'--divider-strong': theme.colors.dividerStrongDark[0],
		'--bright': theme.colors.brightDark[0],
		'--body-shaded': theme.colors.bodyShadedDark[0],
		'--glass-shaded': theme.colors.glassShadedDark[0],
		'--success': theme.colors.successDark[0],
	},
})
