import type { PostgrestError } from '@supabase/supabase-js'
import * as Sentry from '@sentry/react'
import { logger } from './logger'
import { notifications } from '@mantine/notifications'

type ErrorType = Error | PostgrestError | string | unknown

/**
 * Checks if the error is a PostgrestError.
 *
 * @param error - The error to check.
 * @returns True if the error is a PostgrestError, false otherwise.
 */
function isPostgrestError(error: unknown): error is PostgrestError {
	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof (error as PostgrestError).message === 'string'
	)
}

/**
 * Extracts a user-friendly error message from various error types.
 *
 * @param error - The error object caught from an operation.
 * @param fallbackMessage - Optional fallback message to use if the error type is not recognized.
 * @returns A string message to display to the user.
 */
export function getErrorMessage(
	error: ErrorType,
	fallbackMessage = 'An unexpected error occurred. Please try again later.',
): string {
	if (error instanceof Error) {
		// Standard JavaScript Error
		return error.message
	}

	if (isPostgrestError(error)) {
		// Supabase/PostgREST Error
		let message = error.message

		if (error.hint) {
			message += ` Hint: ${error.hint}`
		}

		if (error.details) {
			message += ` Details: ${error.details}`
		}

		return message
	}

	if (typeof error === 'string') {
		// Simple string error
		return error
	}

	// Fallback message for unknown error types
	return fallbackMessage
}

export function toastAndReportError(error: ErrorType, title?: string, message?: string): void {
	// Add rich error details to Sentry
	if (isPostgrestError(error)) {
		Sentry.withScope((scope) => {
			scope.setExtra('PostgREST Code', error.code)
			scope.setExtra('PostgREST Details', error.details)
			scope.setExtra('PostgREST Hint', error.hint)
			Sentry.captureException(error)
		})
	} else if (error instanceof Error) {
		// You can attach any other relevant details for standard Error objects here
		Sentry.captureException(error)
	} else if (typeof error === 'string') {
		Sentry.captureMessage(error)
	} else {
		// If the error is an unknown type, you can still capture it as is
		Sentry.captureException(new Error('Unknown error type'))
	}

	// Log the error using your logger and show notification
	logger.error(`${title || 'Unknown error'}: ${message || 'No Message'}`, error)
	notifications.show({
		title: title || 'Unexpected Error',
		message: message || getErrorMessage(error),
		color: 'red',
		autoClose: false,
	})
}
export function isDatabaseError(error: unknown): error is { code: string } {
	return typeof error === 'object' && error !== null && 'code' in error
}
